import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/mac/github/elemental-zcash/react/docs/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Get started with the Elemental Zcash Design System. Create Zcash inspired designs and apps such as wallets or tipping apps.`}</p>
    <h2>{`Getting Started`}</h2>
    <p>{`The Elemental Zcash Design System is a design specification and component library for designing and building apps for the Zcash ecosystem, or for adding Zcash support to existing apps. `}</p>
    <p>{`Documentation can be found at elementalzcash.com`}</p>
    <h2>{`Components`}</h2>
    <p>{`Cross-platform React.js and Sketch component library for Elemental Zcash. These include themeable and customisable components such as text, buttons, text inputs, etc. In the future will be expanded to other platforms like SwiftUI and Jetpack Compose.`}</p>
    <p>{`Example usage:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Text color=“greens.50” bold>Click here</Text>
`}</code></pre>
    <h2>{`Locales`}</h2>
    <p>{`Zcash specific language and translations for Zcash wallets or apps.`}</p>
    <h2>{`Typography`}</h2>
    <p>{`Typography tokens and styles for Elemental Zcash apps. Interoperable with the component libraries.`}</p>
    <h2>{`Colors`}</h2>
    <p>{`Color tokens and styles for Elemental Zcash apps with JSON and hex values, together with palette generation from core colors. Interoperable with the component libraries.`}</p>
    <h2>{`Icons`}</h2>
    <p>{`Typography tokens and styles for Elemental Zcash apps. Interoperable with the component libraries.`}</p>
    <p>{`Zcash specific icons must be used in accordance with the Zcash Trademark Policy.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      